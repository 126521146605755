<template>
   <nav id="thenav" :style="scrolled > 50 ? 'background-color: #202020;' : ''">
      <ol class="link-inpage">
        <li><a :href="home ? '#' : '/'" :class="passHome ? '' : 'active'">Accueil</a></li>
        <li v-if="home"><a href="#gallery" :class="(passHome && !passGall && !touchBot) ? 'active' : ''">Réalisation</a></li>
        <li v-if="home" style="float:right" ><a href="#contact" :class="(passHome && passGall) || touchBot ? 'active' : ''">Contact</a></li>
      </ol>
    </nav>
</template>

<script>
export default {
  name: 'Nav',
  props: ['headerHeight', 'home'],
  data () {
    return {
      passHome: 0,
      passGall: 0,
      touchBot: 0,
      scrolled: 0
    }
  },
  mounted () {
    if (this.home) {
      let gallery = document.getElementById('gallery')
      this.stateScroll(gallery)
      window.addEventListener('scroll', () => this.stateScroll(gallery))
    }
  },
  methods: {
    /** Mettre à jour où on est arrivé dans le scroll,
     * @param gallery la gallerie
     */
    stateScroll (gallery) {
      if (window) {
        this.scrolled = window.pageYOffset
        this.passHome = this.scrolled > this.headerHeight
        this.passGall = this.scrolled > (this.headerHeight + gallery.offsetHeight)
        this.touchBot = (window.innerHeight + window.pageYOffset) >= document.body.scrollHeight - 2
      }
    }
  }
}
</script>

<style lang="scss">
nav#thenav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 1s;
  ol {
    list-style-type: none;
  }
  li {
    float: left;
    a {
      display: block;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;
      color: #dddddd;
      text-align: center;
      padding: 14px 5px;
      text-decoration: none;
      border-radius: 2px;
      font-family: 'Fibon' , sans-serif;
      font-weight: bold;
      letter-spacing: .1em;
      @media screen and (min-width: 340px) {
        padding: 14px 16px;
      }
      @media screen and (max-width: 380px) {
        letter-spacing: 0em;
      }
      &:link {
        color: #fff;
      }
      &::before, &::after {
        position: absolute;
        opacity: 0;
        width: 0%;
        height: 3px;
        content: '';
        background: #ff7a7a;
        transition: all 0.4s;
      }
      &::before {
        left: 0px;
        top: 0px;
      }
      &::after {
        right: 0px;
        bottom: 0px;
      }
      &:hover {
        color: #ff7a7a;
        &::before, &::after {
          opacity: 1;
          width: 100%;
        }
      }
      &.active {
          &::before, &::after {
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}
</style>


