<template>
  <div class="thanks" data-view>
    <HeaderMouse :title="thanks" :slogan="under" style="height: 100vh;" :home="0"></HeaderMouse>
    <Footer :social="social" :title="title" :slogan="slogan" :home="0"></Footer>    
  </div>
</template>

<script>
import HeaderMouse from './HeaderMouse'
import Footer from './Footer'

export default {
  name: 'Thanks',
  components: { HeaderMouse, Footer },
  data () {
    return {
      thanks: 'Merci',
      under: 'Nous vous contacterons bientôt !',
      title: 'Creamy Web',
      slogan: 'Vos attentes, nos solutions !',
      social: {facebook: '', twitter: 'www.twitter.com', rss: '', youtube: '', linkedin: '', googlePlus: '', instagram: 'www.instagram.com/'}
    }
  }
}
</script>

<style lang="scss">

</style>

