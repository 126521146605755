<template>
  <header ref="header">
    <div class="planets">
      <div class="planet planet--small blue" :style="`top: ${12+ppY}%; left: ${29+ppX}%`"></div>
      <div class="planet planet--small" :style="`top: ${95+ppY}%; left: ${58+ppX}%`"></div>
      <div class="planet planet--medium blue" :style="`top: ${74+ppY}%; left: ${50+ppX}%`"></div>
      <div class="planet" :style="`top: ${6+ppY}%; left: ${2+ppX}%`"></div>
      <div class="planet" :style="`top: ${26+ppY}%; left: ${37+ppX}%`"></div>
      <div class="planet planet--big" :style="`top: ${11+ppY}%; left: ${90+ppX}%`"></div>
      <div class="planet planet--medium blue" :style="`top: ${29+ppY}%; left: ${20+ppX}%`"></div>
      <div class="planet planet--big" :style="`top: ${48+ppY}%; left: ${5+ppX}%`"></div>
      <div class="planet planet--small blue" :style="`top: ${70+ppY}%; left: ${88+ppX}%`"></div>
      <div class="planet planet--small" :style="`top: ${5+ppY}%; left: ${84+ppX}%`"></div>
    </div>
    <div class="txt" data-scroll-main>
      <h1>{{title}}</h1>
      <h2 v-html="slogan"></h2>
    </div>
    <Nav :headerHeight="headerSize" :home="home"></Nav>
  </header>
</template>

<script>
import Nav from './Nav'

export default {
  name: 'HeaderMouse',
  components: { Nav },
  props: ['title', 'slogan', 'home'],
  data () {
    return {
      mouseX: 0,
      mouseY: 0,
      windowWidth:  window.innerWidth,
      windowHeight:  window.innerHeight,
      headerSize: 0
    }
  },
  mounted () {
    this.headerSize = 0
    window.addEventListener('mousemove', e => {
      if (this.passHome) return
      this.mouseX = e.pageX
      this.mouseY = e.pageY
    })
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
      if (this.$refs.header) {
        this.headerSize = this.$refs.header.offsetHeight
      }
    })
  },
  computed: {
    /** Position X de la sourie en pourcentage */
    ppX () {
      return Math.round(this.mouseX / this.windowWidth * 100) / 50
    },
    /** Position Y de la sourie en pourcentage */
    ppY () {
      return Math.round(this.mouseY / this.windowHeight * 100) / 50
    }
  }
}
</script>

<style lang="scss">
header {
  position: relative;
  background-color: #202020;
  width: 100%;
  height: 75%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  .txt {
    position: relative;
    top: 50%;
    transform: translateY(-50%) scale(0.8);
    @media screen and (min-width: 1250px) {
      top: 57%;
    }
    @media screen and (max-width: 465px) {
      top: 50%;
    }
    @media screen and (max-height: 650px) {
      top: 70%;
    }
    h1 {
      font-size: 10vw;
      font-family: 'HeaderFont', sans-serif;
      font-size: 24vw;
      line-height: 15vw;
      font-weight: lighter;
      text-shadow: 0 1px 2px rgba(59, 59, 59, 0.2), 0 5px 10px rgba(59, 59, 59, 0.2);
      text-transform: uppercase;
      @media screen and (min-width: 1250px) {
        font-size: 270px;
        line-height: 185px;
      }
      @media screen and (max-width: 465px) {
        font-size: 28vw;
        line-height: 70px;
      }
      @media screen and (max-height: 650px) {
        font-size: 17vw;
        line-height: 100px;
      }
    }
    h2 {
      margin-top: 15px;
      font-size: 30px;
      font-family: 'Fibon' , sans-serif;
      font-weight: lighter;
      letter-spacing: .4em;
      text-shadow: 0 1px 2px rgba(119, 119, 119, 0.2), 0 5px 10px rgba(119, 119, 119, 0.2);
      span {
        display: inline-block;
      }
      @media screen and (max-width: 370px) {
        margin-top: 20px;
      }
    }

  }
  .planets {
    height: 90%;
    position: absolute;
    top: 0; left: 0; right: 0;
    .planet {
      position: absolute;
      width: 76px;
      height: 76px;
      border-radius: 50%;
      background: #ff7a7a; /* Old browsers */
      background: linear-gradient(45deg, #ff7a7a 0%,#ea5b8d 99%);
      @media screen and (min-width: 800px) {
        animation: 3s infinite alternate floatAnim;
      }
      @keyframes floatAnim {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-15px);
        }
      }
      &--small {
        width: 50px;
        height: 50px;
      }
      &--medium {
        width: 150px;
        height: 150px;
      }
      &--big {
        width: 200px;
        height: 200px;
      }
      &.blue {
        background: #7ab1ff; /* Old browsers */
        background: linear-gradient(45deg, #7ab1ff 0%,#8f40a5 99%);
      }
    }
  }
}
</style>
