<template>
  <div class="product">
    <h3 v-html="data.title"></h3>
    <p v-for="(item, i) in data.rows" :key="i" v-html="item"></p>
    <span class="b-contact">
      <a href="#contact" @click="$emit('producType', data.title)">Contacter nous !</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Product',
  props: [ 'data' ],
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
.product {
  background-color: #fff;
  display: inline-block;
  position: absolute;
  width: 230px;
  height: 380px;
  padding: 20px;
  margin: 0 10px 10px 10px;
  font-family: 'Fibon' , sans-serif;
  border-radius: 1px;
  box-shadow: 5px 5px 0px 0px #202020;
  transition: all 1s ease-in-out !important;
  h3 {
    text-align: center;
    margin-bottom: 15px;
    font-family: 'Fibon' , sans-serif;
    letter-spacing: 0.05em;
    font-size: 18px;
    b {
      font-size: 20px;
      text-shadow: 0 0 0 #000;
    }
  }
  p {
    padding: 15px 0;
    border-bottom: 2px solid #383838;
    display: block;
  }
  .little {
    color: grey;
    font-size: smaller;
  }
  span.b-contact {
    display: block;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px;
    background-color: #383838;
    border-radius: 2px;
    position: absolute;
    bottom: 25px;
    width: 200px;
    a {
      padding: 20px;
      color: #fff;
    }
  }
}
</style>
