<template>
  <div id="gallery">
    <h2>Exemples de solutions créées pour nos clients :</h2>
    <div class="item invisible" v-for="(img, index) in images" :key="index" ref="gallimg" data-scroll>
      <h3 v-html="img.title+' :'"></h3>
      <div class="box">
        <div class="wrap" @click.stop="clickOnClose($event, 0)" :class="img.class">
          <close-button @btn-click.stop="clickOnClose($event, 1)" color="#fff"></close-button>
          <picture>
            <source :srcset="img.src[1]" type="image/webp">
            <source :srcset="img.src[0]" type="image/png"> 
            <img :src="img.src[0]" :alt="img.alt" :title="img.title" :style="`margin: ${img.margin}`" @click.stop="clickOnImg($event)">
          </picture>
        </div>
        <p v-html="img.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "./CloseButton";

export default {
  name: "Gallery",
  components: { CloseButton },
  props: {
    images: Array
  },
  data() {
    return {
      imgclicked: undefined
    };
  },
  methods: {
    /**
     * Quand on clique sur une image
     * @param e event
     */
    clickOnImg(e) {
      let path = e.path || (e.composedPath && e.composedPath())
      this.imgclicked = path[2]
      this.imgclicked.classList.add("clicked")
      document.body.style.overflow = 'hidden'
      document.getElementById('thenav').style.display = 'none'
    },
    /**
     * Quand on clique pour fermer
     * @param e event
     * @param i chemin
     */
    clickOnClose(e, i) {
      let path = e.path || (e.composedPath && e.composedPath())
      this.imgclicked = path[i]
      this.imgclicked.classList.remove("clicked")
      document.body.style.overflow = 'auto'
      document.getElementById('thenav').style.display = 'block'
    }
  }
};
</script>

<style lang="scss">
#gallery {
  margin-bottom: 50px;
  h2 {
    max-width: 750px;
    margin: 30px auto;
    text-align: center;
    color: #fff;
    font-family: 'Fibon' , sans-serif;
    font-weight: lighter;
    font-size: 30px;
    padding: 20px 0;
    text-transform: uppercase;
  }
  .item {
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
    &:nth-child(2) {
      margin-top: 10px;
    }
    display: block;
    clear: both;
    position: relative;
    width: 100%;
    h3 {
      color: #fff;
      font-family: 'Fibon' , sans-serif;
      font-weight: lighter;
      font-size: 20px;
      padding: 10px 0 15px 5px;
      @media screen and (max-width: 750px) {
        padding-left: 25px;
      }
    }
    .box {
      display: flex;
      align-items: center;
      @media screen and (max-width: 560px) {
        flex-wrap: wrap-reverse;
        justify-content: center;
        padding-bottom: 20px;
      }
      .wrap {
        img {
          display: block;
          max-width: 220px;
          height: auto;
          cursor: zoom-in;
          z-index: 800;
          box-shadow: 5px 5px 0px 0px #707070;
        }
        .close-button {
          display: none;
        }
        &.clicked {
          background-color: rgba(29, 29, 29, 0.84);
          position: fixed;
          width: 100% !important;
          height: 100% !important;
          top: 0; bottom: 0;
          left: 0; right: 0;
          z-index: 999;
          cursor: zoom-out;
          .close-button {
            display: initial;
          }
          img {
            position: fixed;
            max-width: 100%;
            max-height: 90%;
            max-width: 90%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 15px;
            background-color: #eaeaea;
            cursor: initial;
          }
        }
      }
      p {
        color: #fff;
        padding: 30px;
        font-size: 18px;
        font-family: 'Fibon' , sans-serif;
      }
    }
  }
}
</style>
