<template>
  <div class="contact-bloc" :style="`background: linear-gradient(${degradient}deg, #ff7a7a 0%,#ea5b8d 99%);`">
    <div class="bloc invisible" data-scroll>
      <h2>Contact</h2>
      <form id="contact" class="contact" @submit="checkForm" method="POST" ref="contact">
        <p class="message" v-if="error">{{error}}</p>
        <input type="text" name="name" v-model="name" placeholder="Nom" :class="(error && !name) ? 'red-border' : ''"/>
        <input type="text" name="tel"  v-model="tel" placeholder="Téléphone"/>
        <input type="text" name="_replyto" v-model="email" placeholder="Email" :class="(error && (!email || !validEmail(email))) ? 'red-border' : ''"/>
        <input type="text" name="society" v-model="society" placeholder="Société"/>
        <input type="text" name="url" v-model="url" placeholder="L'url de votre site internet" v-if="askUrl"/>
        <input type="text" name="objet" v-model="obj" placeholder="Objets" :class="(error && !obj) ? 'red-border' : ''"/>
        <textarea type="text" name="message" v-model="msg" placeholder="Décrivez nous vos envies, vos attentes..." :class="(error && (!msg || msg.length < 20)) ? 'red-border' : ''"/>
        <input type="hidden" name="_next" value="/thanks"/>
        <input type="hidden" name="_subject" value="CreamyWeb - Contact"/>
        <input type="hidden" name="_language" value="fr"/>
        <input type="text" name="_gotcha" style="display:none"/>
        <span>
          <input type="submit" value="Contacter nous !" class="price-button">
        </span>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: ['askUrl', 'degradient', 'objet'],
  data () {
    return {
      error: null,
      name: null,
      tel: null,
      email: null,
      society: null,
      url: null,
      msg: null,
      baseMail: 'contact'
    }
  },
  mounted () {
    this.$refs.contact.setAttribute('action', '//formspree.io/' + this.baseMail + '@' + 'creamyweb' + '.' + 'com')
  },
  methods: {
    checkForm (e) {
      this.error = null
      if (!this.name) {
        this.error = 'Quel est votre nom ?'
      } else if (!this.email) {
        this.error = 'Quelle est votre adresse mail ?'
      } else if (!this.validEmail(this.email)) {
        this.error = 'Cette adresse mail semble invalide...'
      } else if (!this.obj) {
        this.error = 'Quel est l\'objet de votre demande ?'
      } else if (!this.msg) {
        this.error = 'Dites nous en plus sur vos attente :) !'
      } else if (this.msg.length < 20) {
        this.error = 'Dites nous un peu plus ;)'
      } else {
        return // → send data
      }
      e.preventDefault()
    },
    validEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  computed: {
    obj: {
      get () {
        return this.objet.replace(/<\/?[^>]+(>|$)/g, "")
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.contact-bloc {
  position: relative;
  width: 100%;
  background: #202020;
  // background: linear-gradient(to bottom, #202020 33%,#0e0e0e 95%);
  background: linear-gradient(45deg, #ff7a7a 0%,#ea5b8d 99%);
  .bloc {
    max-width: 800px;
    margin: auto;
    position: relative;
    h2 {
      color: #fff;
      font-family: arial;
      font-size-adjust: 0.5;
      letter-spacing: 0;
      font-weight: 800;
      font-size: 120px;
      transform: rotate(90deg);
      position: absolute;
      top: 187px;
      left: -155px;
      // text-shadow: 1px 1px 2px rgb(132, 127, 215), 1px 2px 4px rgb(245, 107, 132);
      text-shadow: 3px -2px 0px #202020, 2px -4px 0px #202020;
      @media (max-width: 650px) {
        display: none;
      }
    }
    .contact {
      position: relative;
      z-index: 1;
      padding: 50px 0 50px 128px;
      text-align: center;
      @media (max-width: 900px) {
        max-width: 60%;
        padding: 50px 0;
        margin: auto;
      }
      input, textarea {
        font-family: sans-serif;
        outline: 0;
        background: #f2f2f2;
        width: 100%;
        border: 0;
        border-radius: 2px;
        margin: 0 0 15px;
        padding: 15px;
        font-size: 14px;
        box-sizing: border-box;
        box-shadow: 4px 4px 0px 1px #202020;
        &:hover {
          background: #dbdbdb;
        }
      }
      span {
        width: 100%;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: -18px;
          left: 4px;
          width: 100%;
          height: 46px;
          background-color: #202020;
          z-index: -1;
          border-radius: 2px;
        }
      }
      input.price-button {
        font-family: sans-serif;
        width: auto;
        text-transform: uppercase;
        cursor: pointer;
        color: #fff;
        background-color: #424242;
        box-shadow: 0 200px 100px -160px #202020 inset;
        margin: 10px 0;
        width: 100%;
        transition-duration: 1s;
        transition-property: all;
        &:hover {
          box-shadow: 0 200px 100px -300px #202020 inset;
        }
        &:active {
          animation-name: push;
          animation-duration: 0.3s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
        }
        @keyframes push {
          50% { transform: scale(1.1); }
          100% { transform: scale(1); }
        }
      }
      textarea {
        min-height: 100px;
      }
      .message {
        margin: 5px;
        color: white;
        font-style: italic;
        font-size: 15px;
        font-family: sans-serif;
        animation-name: colorChange;
        animation-duration: 0.2s;
        animation-iteration-count: 2;
      }
      .red-border {
        border: 1px solid #ff6c28;
      }
      @keyframes colorChange {
        from {color: white;}
        to {color: #ff6c28;}
      }
    }
  }
}

</style>
