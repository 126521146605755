import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Home from './components/Home.vue'
import Thanks from './components/Thanks.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/thanks',
      name: 'thanks',
      component: Thanks
    }
  ],
  mode: 'history'
})

const app = new Vue({
  render: h => h(App),
  router: router
})

router.beforeResolve(async (to, from, next) => {
  try {
    const components = router.getMatchedComponents(to);
    // By using `await` we make sure to wait
    // for the API request made by the `fetch()`
    // method to resolve before rendering the view.
    await Promise.all(components.map(x => x.fetch));
  } catch (error) {
    // This is the place for error handling in
    // case the API request fails for example.
    console.log(error);
  }
  return next();
});

app.$mount('#app')
