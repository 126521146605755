<template>
  <div class="social-icons-box">
    <ul class="social-icons">
      <li v-if="use.facebook"><a :href="use.facebook" target="_blank" class="social-icon"><img src="../assets/social/facebook.svg" alt="facebook-link" class="fa fa-facebook"></a></li>
      <li v-if="use.twitter"><a :href="use.twitter" target="_blank" class="social-icon"><img src="../assets/social/twitter.svg" alt="twitter-link" class="fa fa-twitter"></a></li>
      <li v-if="use.instagram"><a :href="use.instagram" target="_blank" class="social-icon"><img src="../assets/social/instagram.svg" alt="instagram-link" class="fa fa-instagram"></a></li>
      <li v-if="use.youtube"><a :href="use.youtube" target="_blank" class="social-icon"><img src="../assets/social/youtube.svg" alt="youtube-link" class="fa fa-youtube"></a></li>
      <li v-if="use.linkedin"><a :href="use.linkedin" target="_blank" class="social-icon"><img src="../assets/social/linkedin.svg" alt="linkedin-link" class="fa fa-linkedin"></a></li>
      <li v-if="use.googlePlus"><a :href="use.googlePlus" target="_blank" class="social-icon"><img src="../assets/social/googlePlus.svg" alt="google-plus-link" class="fa fa-google-plus"></a></li>
      <li v-if="use.rss"><a :href="use.rss" target="_blank" class="social-icon"><img src="../assets/social/rss.svg" alt="rss-link" class="fa fa-rss"></a></li>
    </ul>
</div>
</template>

<script>
export default {
  name: 'SocialLink',
  props: ['use'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.social-icons-box {
  display: inline-block;
}
ul {
  padding: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  box-shadow: 3px 3px 0px 0px #707070;
  &:hover {
    box-shadow: 1px 0px 0px 0px #707070;
  }
}
.social-icons a {
  color: #fff;
  text-decoration: none;
  background: #322f30;
}
.fa {
  padding: 20px 25px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
}
.fa-facebook {
  background-color: #322f30;
}
.fa-facebook:hover {
  background-color: #3d5b99;
}
.fa-twitter {
  background-color: #322f30;
}
.fa-twitter:hover {
  background-color: #00aced;
}
.fa-rss {
  background-color: #322f30;
}
.fa-rss:hover {
  background-color: #eb8231;
}
.fa-youtube {
  background-color: #322f30;
}
.fa-youtube:hover {
  background-color: #e64a41;
}
.fa-linkedin {
  background-color: #322f30;
}
.fa-linkedin:hover {
  background-color: #0073a4;
}
.fa-google-plus {
  background-color: #322f30;
}
.fa-google-plus:hover {
  background-color: #e25714;
}
.fa-instagram {
  background-color: #322f30;
}
.fa-instagram:hover {
  background: #d6249f;
}
</style>
