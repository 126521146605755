<template>
  <a :href="link" class="close-button" @click="$emit('btn-click', $event)" :style="`color: ${color};`"></a>
</template>

<script>
export default {
  name: 'CloseButton',
  props: ['link', 'color']
}
</script>

<style lang="scss">
$height: 30px;
$width: 30px;
$thickness: $height / 5;

.close-button {
  /* added style */
  position: fixed;
  top: 15px;
  right: $width;
  /* default */
  height: $height;
  width: $width;
  // position: relative;
  box-sizing: border-box;
  line-height: $height;
  // display: inline-block;
  cursor: pointer;
  z-index: 999;
  &::before, &::after {
    background-color: currentColor;
    transform: rotate(-45deg);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: - $width / 2;
    display: block;
    height: $thickness;
    width: $width;
    transition: all 0.2s ease-out;
  }
  &::after {
    transform: rotate(45deg);
  }
  &:hover {
    &::before, &::after {
      transform: rotate(0deg);
    }
  }
}
</style>

