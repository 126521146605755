<template>
  <footer>
    <h3>{{title}} - <span v-html="slogan"></span></h3>
    <div class="box">
      <ul class="link-inpage">
        <li><a href="#">Haut de page</a></li>
        <li><a href="/#gallery">Réalisation</a></li>
        <li><a href="/#contact">Contact</a></li>
      </ul>
      <SocialLink :use="social"></SocialLink>
      <p class="copyright">copyright © {{copyrightYear}} {{title}}</p>
    </div>
  </footer>
</template>

<script>
import SocialLink from './SocialLink'

export default {
  name: 'Footer',
  components: { SocialLink },
  props: ['social', 'title', 'slogan', 'home'],
  data () {
    return {
      copyrightYear: 2018
    }
  },
  created () {
    // date auto pr le copyright
    let date = new Date().getFullYear()
    if (date == '2018') {
      this.copyrightYear = '2018'
    } else {
      this.copyrightYear = '2018-' + date
    }
  }
}
</script>

<style lang="scss">
footer {
  padding: 50px 0 5px 50px;
  position: relative;
  color: white;
  font-family: 'Gothic A1', sans-serif;
  background-color: #0e0e0e;
  .box {
    padding: 50px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .link-inpage {
      padding: 0 50px 50px 0;
      list-style: none;
      li {
        padding: 10px 0;
        a {
          text-decoration: none;
          color: white;
          color: #fff;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            display: inline-block;
            height: 7px;
            width: 100%;
            border-bottom: 1px solid;
            margin-top: 10px;
            opacity: 0;
            transition: opacity 0.35s, transform 0.35s;
            transform: scale(0,1);
          }
          &:hover {
            &:after {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
    .social-icons-box {
      margin: 0 50px 50px 0;
    }
    .copyright {
      text-transform: uppercase;
      height: 75px;
    }
  }
}
</style>
