<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'app'
}
</script>
<style lang="scss">
$bg-color: #202020;

@font-face {
  font-family: 'Fibon';
  src: url('./assets/fonts/Fibon_Sans_Regular.otf');
}

* { margin: 0; padding: 0; }

a:link {
  text-decoration: none;
  color: #ff7a7a;
}

body, html, #app {
  background-color: $bg-color;
  width: 100%;
  height: 100%;
}
</style>