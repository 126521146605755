<template>
  <div class="products" :style="`background: linear-gradient(${degradient}deg, #ff7a7a 0%,#ea5b8d 99%);`">
    <div class="wrap invisible" data-scroll>
      <div class="card-nav card-nav__right" @click="left -= (left > 0) ? 1 : 0" :style="navLeftOpa"></div>
      <div class="container">
        <Product v-for="(card, i) in cards" :key="i" :data="card" :style="order(i)" @producType="(t) => $emit('producType', t)"></Product>
      </div>
      <div class="card-nav card-nav__left" @click="left += (left < cards.length-maxItem) ? 1 : 0" :style="navRightOpa"></div>
    </div>
  </div>
</template>

<script>
import Product from './Product'

export default {
  name: 'Products',
  props: ['degradient'],
  components: { Product },
  data () {
    return {
      left: 0,
      maxItem: 1,
      cards: [
        { title : "Site vitrine unique",
          rows: [
            "Responsive : compatible smartphones & tablettes",
            "Nom de domaine inclus",
            `Hébergement, support et maintenance <span class="little">90€/an</span>`
          ]
        },
        { title : "<b>Redesign de votre site</b>",
          rows: [
            "Nous apportons de la fraîcheur à votre site !",
            `Faites plaisir aux <a href="https://www.statista.com/statistics/277125/share-of-website-traffic-coming-from-mobile-devices/">52%</a> de visiteurs qui sont sur smartphones et tablettes: rendez votre site responsive!`,
          ]
        }, 
        { title : "Site Wordpress",
          rows: [
            "Le design personnalisé de votre site appliqué à WordPress",
            "Responsive : compatible smartphones & tablettes",
            "Nom de domaine inclus",
            `Hébergement, support et maintenance <span class="little">90€/an</span>`
          ]
        },
        { title : "Newsletter",
          rows: [
            "Responsive : compatible smartphones & tablettes",
            "Testée avant l'envoi",
            "Intégration ou création graphique",
            "Images chez un CDN pour une ouverture plus rapide !",
            "Prêt à l'envoi !"
          ]
        },
        { title : "Solution Web",
          rows: [
            "Un besoin spécifique ? Nous pouvons créer la solution adaptée à celui-ci",
          ]
        }, 
      ]
    }
  },
  mounted() {
    this.getNbOfItem ()
    window.addEventListener('resize', this.getNbOfItem)
  },
  methods: {
    order (i) {
      let v = i - this.left
      return 'left:' + v * 290 + 'px;'
    },
    getNbOfItem () {
      if (window.innerWidth > 870) {
        this.maxItem = 3
      } else if (window.innerWidth > 580) {
        this.maxItem = 2
      } else {
        this.maxItem = 1
      }
    }
  },
  computed: {
    navLeftOpa () {
      if (this.left == 0) return { opacity: 0.5, cursor: 'initial' }
      return { opacity: 1, cursor: 'pointer' }
    },
    navRightOpa () {
      if (this.left >= this.cards.length-this.maxItem) return { opacity: 0.5, cursor: 'initial' }
      return { opacity: 1, cursor: 'pointer' }
    }
  }
}
</script>

<style lang="scss">
.products {
  width: 100%;
  padding: 20px 0;
  background: linear-gradient(45deg, #ff7a7a 0%,#ea5b8d 99%);
  .wrap {
    position: relative;
    max-width: 870px;
    @media screen and (max-width: 960px) {
      max-width: 100%;
    }
    margin: auto;
    .container {
      max-width: 870px;
      @media screen and (max-width: 870px) {
        max-width: 580px;
      }
      @media screen and (max-width: 580px) {
        max-width: 300px;
      }
      margin: auto;
      height: 430px;
      position: relative;
      overflow: hidden;
    }
    .card-nav {
      position: absolute;
      top: 190px;
      width: 30px;
      height: 30px;
      padding: 10px;
      box-sizing: border-box;
      color: #202020;
      border-top: 7px solid;
      border-right: 7px solid;
      cursor: pointer;
      z-index: 100;
      transition: transform 150ms linear;
      &__right {
        left: -30px;
        transform: rotate(-135deg);
         @media screen and (max-width: 960px) {
          left: 3%;
        }
      }
      &__left {
        right: -30px;
        transform: rotate(45deg);
        @media screen and (max-width: 960px) {
          right: 3%;
        }
      }
    }
  }
}
</style>
